// ============================
//     Avtar css start
// ============================

.avtar {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: $border-radius;
  font: {
    size: 18px;
    weight: 600;
  }
  width: 48px;
  height: 48px;

  &.avtar-xxs {
    width: 16px;
    height: 16px;
    font-size: 14px;
    border-radius: 5px;
  }
}
