/* You can add global styles to this file, and also import other style files */

/* Importing Bootstrap SCSS file. */
@import 'bootstrap/scss/bootstrap';

// main framework
@import 'scss/settings/preset-variables';
@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
$pc-body-bg: map-get($preset-colors, 'preset-1', 'body');
$pc-blue: map-get($preset-colors, 'preset-1', 'primary');
$pc-secondary: map-get($preset-colors, 'preset-1', 'secondary');
@import 'scss/settings/custom-variables';
@import 'scss/settings/theme-variables';

// @import 'node_modules/bootstrap/scss/bootstrap';
// main framework
@import 'scss/themes/generic';
@import 'scss/themes/general';

// Layouts
@import 'scss/themes/layouts/layouts';

// Componants
@import 'scss/themes/components/components';

// pages
@import 'scss/themes/pages/page';

@import 'scss/themes/customizer';
@import 'scss/style-preset.scss';

// fonts
@import url('scss/fonts/berry/berry-icons.css'); /** custom font icons - berry **/
@import 'scss/fonts/tabler-icons.min.css';
